export function scrollToError() {
  const errors = document.querySelector('ul.errors')
  if (errors) {
    let target = errors
    if (Element.prototype.closest) {
      const section = errors.closest('section')
      if (section) {
        target = section
      }
      else {
        const form = errors.closest('form')
        if (form) {
          target = form
        }
      }
    }
    target.scrollIntoView()
  }
}

export function onNumPbcSelect(vacuumId) {
  const vacuum = document.getElementById(vacuumId)
  if (event.target.value == 3) {
    vacuum.disabled = true
    vacuum.required = false
  }
  else {
    vacuum.disabled = false
    vacuum.required = true
  }
  const validator = new Parsley.Factory(vacuum);
  validator.reset()
}

export function onGridTypeSelect(subGridTypeId) {
  const subGridType = document.getElementById(subGridTypeId)
  for (const o of subGridType.querySelectorAll('option')) {
    const types = o.getAttribute('data-grid-type')
    if (types) {
      o.disabled = !(types.includes(event.target.value))
    }
  }
  const validator = new Parsley.Factory(vacuum);
  validator.reset()
}
